<template>
  <!-- 应答内容表单 -->
  <div style="max-width: 400px;margin: 0 auto;">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="formCheck"
      label-width="110px"
      class="login-form"
    >
      <el-form-item prop="itemName" label="选择商品" v-if="category === 'peitao'">
        <div class="flex">
          <div style="width:calc(100% - 80px)">
            <el-input disabled v-model="ruleForm.itemName" placeholder="请选择产品"></el-input>
          </div>
          <div style="margin-left:10px">
            <el-button @click="showMesList()">选择</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="price" label="出价">
        <el-input
          type="number"
          style="width:calc(100% - 80px)"
          min="1"
          placeholder="请填写出价"
          v-model="ruleForm.price"
        ></el-input>
      </el-form-item>
      <el-form-item prop="giveDate" label="交货时间" v-if="category !== 'dayang'">
        <el-date-picker
          style="width:calc(100% - 80px)"
          v-model="ruleForm.giveDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>
      </el-form-item>
      <div style="padding-top:30px;" class="flex flex-justify-around">
        <el-button type="primary" @click="hide()" style="width: 90px;" plain>取消</el-button>
        <el-button type="primary" @click="submitForm()" style="width: 90px;">确认</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import _ from "lodash";
import { peitaoOfferAdd, peitaoOfferModify } from "@/service/want";
export default {
  props: {
    updateMsg: {
      type: Object,
      default: () => {}
    },
    category: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      rules: {
        price: [{ required: true, message: "请填写出价", trigger: "blur" }],
        giveDate: [{ required: true, message: "请选择日期", trigger: "blur" }],
        itemName: [{ required: true, message: "请选择产品", trigger: "blur" }]
      },
      ruleForm: {
        requirementId: this.$route.params.id,
        itemId: "",
        price: this.updateMsg.price || "",
        giveDate: this.updateMsg.giveDate || ""
      },
      selectType: 2,
      select_item: {}
    };
  },
  // watch:{
  //     form:{
  //         handler() {
  //             this.ruleForm = this.form;
  //         },
  //         deep: true
  //     }
  // },
  created() {
    this.comp = this.$store.state.loginUser.comp;
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
    submitForm() {
      this.$refs["formCheck"].validate(valid => {
        if (valid) {
          this.submit();
        }
      });
    },
    submit: _.debounce(function() {
      let _item = _.clone(this.ruleForm);
      let text = null;
      this.$delete(_item, "itemName");
      _item.giveDate = new Date(_item.giveDate).getTime();
      console.log(_item);
      let obj = {
        requirementId: _item.requirementId
      };
      if (this.comp && this.comp.comp_type) {
        switch (this.comp.comp_type) {
          case 1:
            obj.peitao = {
              itemId: _item.itemId,
              price: _item.price,
              giveDate: _item.giveDate
            };
            break;
          case 2:
            obj.gaikuang = {
              price: _item.price,
              giveDate: _item.giveDate
            };
            break;
          case 4:
            obj.dayang = {
              itemId: _item.itemId,
              price: _item.price
            };
            break;
        }
      }
      if (this.updateMsg.id) {
        obj.id = this.updateMsg.id;
        peitaoOfferModify(obj)
          .then(rst => {
            this.hide();
            this.$emit("onSuccess");
            this.$message.success("提交成功");
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      } else {
        peitaoOfferAdd(obj)
          .then(rst => {
            this.$emit("hide");
            this.$emit("onSuccess");
            this.$message.success("提交成功");
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      }
    }, 1000)
  }
};
</script>
<style scoped>
.list > div {
  padding: 5px 0;
}
.list >>> input[type="number"] {
  padding: 0 0 0 15px;
}
</style>